/* eslint-disable react/jsx-one-expression-per-line */
import React from "react"
import { css } from "@emotion/core"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import { fonts, mediaQueries, colors } from "../../styles"
import Layout from "../../components/layout"
import Footer from "../../components/Footer"
import SubNav from "../../components/SubNav/SubNav"

const parent = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(9, 300px);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  font-family: ${fonts.sans};
  .div1 {
    grid-area: 1 / 1 / 4 / 2;
  }
  .div2 {
    grid-area: 4 / 1 / 7 / 2;
  }
  .div3 {
    grid-area: 7 / 1 / 10 / 2;
  }
  .div4 {
    grid-area: 1 / 2 / 4 / 3;
  }
  .div5 {
    grid-area: 4 / 2 / 7 / 3;
  }
  .div6 {
    grid-area: 7 / 2 / 10 / 3;
  }
  ${mediaQueries.phoneLarge} {
    display: block;
  }
`

const linkStyle = css`
  color: ${colors.deepblue};
`
export default ({ data }) => {
  const imageSet = data.allFile.edges.reduce((result, current) => {
    result[current.node.base.split(".")[0].slice(-1)] =
      current.node.childImageSharp.fluid
    return result
  }, {})

  return (
    <Layout title="大学院课程">
      <div css={parent}>
        {/* 1 */}
        <div
          className="div1"
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${colors.deepblue};
            ${mediaQueries.phoneLarge} {
              padding: 300px 0;
            }
          `}
        >
          <div>
            <SubNav fill={colors.lightgray} />
            <h1
              css={css`
              font-family: ${fonts.sans};
              color: white;
              &:before {
                content: "";
                height: 33px;
                width: 33px;
                border-radius: 50%;
                position: relative;
                top: 4px;
                margin-right: 8px;
                background-color: white;
                display: inline-block;
            `}
            >
              课程-大学院
            </h1>
          </div>
        </div>
        {/* 2 */}
        <div
          className="div2"
          css={css`
            font-size: 40px;
            font-weight: 500;
            color: rgb(3, 10, 48);
            padding: 20px;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            ${mediaQueries.phoneLarge} {
              display: none;
            }
          `}
        >
          <span>KIYOMIZU</span>
          <span>ART</span>
          <span>EDUCATION</span>
        </div>
        {/* 3 */}
        <div
          className="div3"
          css={css`
            background-color: rgb(196, 195, 195);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              display: grid;
              grid-template-columns: 1fr 1fr;
              margin-bottom: 40px;
            `}
          >
            <Img fluid={imageSet[1]} />
            <Img fluid={imageSet[2]} />
          </div>
          <div
            css={css`
              display: grid;
              grid-template-columns: 1fr 1fr;
              margin-bottom: 40px;
            `}
          >
            <Img fluid={imageSet[3]} />
            <Img fluid={imageSet[4]} />
          </div>
          <div
            css={css`
              background-color: rgb(3, 10, 48);
              height: 150px;
            `}
          ></div>
        </div>
        {/* 4 */}
        <div
          className="div4"
          css={css`
            background-color: white;
          `}
        >
          <ul
            css={css`
              display: flex;
              justify-content: space-around;
              font-size: 1.4rem;
              font-weight: 600;
              list-style: none;
              margin: 0;
              padding: 1rem 0;
              background-color: rgb(218, 215, 214);
              ${mediaQueries.phoneLarge} {
                display: none;
              }

              li {
                position: relative;
              }

              li:before {
                content: "";
                height: 1.4rem;
                width: 1.4rem;
                border-radius: 50%;
                border: 1px solid ${colors.deepblue};
                position: relative;
                top: 4px;
                margin-right: 8px;
                display: inline-block;
              }

              li.active:before {
                background-color: ${colors.deepblue};
              }

              ul {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                padding-left: 0;
                left: 0;
                top: 21px;
                display: none;
                background: white;
              }

              li:hover > ul,
              li ul:hover {
                visibility: visible;
                opacity: 1;
                display: block;
                min-width: 250px;
                text-align: left;
                box-shadow: 0px 3px 5px -1px #ccc;
              }

              li ul li {
                clear: both;
                width: 100%;
                text-align: left;
                padding: 10px;
                margin: 0;
                list-style: none;
                transition: all 0.5s ease;
              }

              li ul li:not(:first-of-type) {
                border-top: 1px solid gray;
              }

              li ui li:before {
                border-style: none;
              }

              li ul li:hover {
                transition: all 0.3s ease;
                cursor: pointer;
                color: white;
                background-color: rgb(1, 9, 90);

                &:before {
                  background-color: white;
                }
              }
            `}
          >
            <li>
              <Link to="/" css={linkStyle}>
                清水艺术
              </Link>
            </li>
            <li>
              <Link to="/about" css={linkStyle}>
                关于清水
              </Link>
            </li>
            <li>
              <Link to="/schools" css={linkStyle}>
                学校介绍
              </Link>
              <ul>
                <li>东京五美大</li>
                <li>关西三美大</li>
                <li>国公立五艺大</li>
                <li>综合类高排名院校</li>
                <li>其他院校</li>
              </ul>
            </li>
            <li className="active">
              课程 -{" "}
              <Link to="/class/normal" css={linkStyle}>
                学部
              </Link>
              ・
              <Link to="/class/master" css={linkStyle}>
                大学院
              </Link>
            </li>
          </ul>
          <div
            css={css`
              padding: 40px;
              font-weight: 600;
              ${mediaQueries.phoneLarge} {
                p {
                  font-size: 14px;
                  line-height: 26px;
                }
                h3 {
                  line-height: 2rem;
                }
                padding: 50px 20px;
              }
            `}
          >
            <h3
              css={css`
                font-family: ${fonts.sans};
              `}
            >
              针对于考学所需的四点内容来进行指导和学习
            </h3>
            <ul>
              <li>是否具备了开始做研究的能力</li>
              <li>是否已经有了研究方向</li>
              <li>是否日本语已经有表达的能力</li>
              <li>是否可以根据自己的研究内容书写小论文</li>
            </ul>
            <p>我们的课程是针对以上同学们所不足的点来进行负担.</p>
            <h3
              css={css`
                font-family: ${fonts.sans};
              `}
            >
              学习内容涉及
            </h3>
            <ul>
              <li>研究题目确定</li>
              <li>研究内容整理, 指导</li>
              <li>研究计划书整理书写</li>
              <li>以往作品整理</li>
              <li>相关的作品集在老师的指导下制作, 调整, 排版, 最终成册</li>
            </ul>
          </div>
        </div>
        {/* 5 */}
        <div className="div5">
          <Img
            fluid={imageSet[5]}
            css={css`
              height: 100%;
            `}
          />
        </div>
        {/* 6 */}
        <div
          className="div6"
          css={css`
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            ${mediaQueries.phoneLarge} {
              display: none;
            }
          `}
        >
          <h1
            css={css`
            font-family: ${fonts.sans};
            color: rgb(44, 44, 44);
            &:before {
              content: "";
              height: 2rem;
              width: 2rem;
              border-radius: 50%;
              position: relative;
              top: 3px;
              margin-right: 10px;
              background-color: rgb(44, 44, 44);
              display: inline-block;
          `}
          >
            课程-学部
          </h1>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "master" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
